.filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.filters__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  padding: 10px 0;
}