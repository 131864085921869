.video-example {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    margin: 0 auto;   
}

.video-example-vertical {
    width: 300px;
    overflow: hidden;
    position: relative;
    left: 30px;
}

.shakeLittle {
    animation: shakeAnimation 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.shakeMedium {
    animation: shakeAnimation 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

  @keyframes shakeAnimation {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }