.App {
  text-align: center;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 92px;
  height: 92px;

  margin: 20px 40px;
}

.team-container {
  font-weight: bold;
}

.team-container h1{
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  background-color: #282c34;
  height: 100vh;
}

.left {
  width: 80%;
  background-color: #000;
  color: white;
}

.right {
  width: 20%;
  padding: 10px;
  background-color: #282c34;
  color: white;
}
