.image-container {
 position: relative;
 width: 900px;
 margin: 0 auto;
 top: -600px;
}

.camera-image {
 position: relative;
 width: 100%;
}

.image-container::before {
 content: "";
 position: absolute;
 top: 155px;
 left: 80px;
 width: 20px;
 height: 20px;
 background-color: #fff;
 z-index: 1;
 border-radius: 50%;
}

.image-container--startRecording::before{
 animation: recording 1s infinite ease-in-out;
}

.image-container--stopRecording::before{
 animation: stop-recording 1s ease-in-out;
}

@keyframes recording {
 0% { background-color: red; }
 50% { background-color: white; }
 100% { background-color: red; }
}

@keyframes stop-recording {
 0% { background-color: red; }
 20% { background-color: white; }
 40% { background-color: red; }
 60% { background-color: white; }
 80% { background-color: red; }
 100% { background-color: white; }
}
.selectors {
 position: relative;
 width: 800px;
 top: -600px;
 margin: 0 auto;
 display: flex;
 text-align: center;
 justify-content: space-between;
}
