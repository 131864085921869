.camera-container {
 position: absolute;
 top: 160px;
 left: 165px;

 width: 625px;
 height: 435px;

 color: #fff;
 font-weight: bold;
}

.camera-actions {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);

 z-index: 2;
 cursor: pointer;

 opacity: 1;
 visibility: visible;
}

.camera-container .stop-video-btn {
 opacity: 0;
 visibility: hidden;

 transition: all .5s ease-in-out;
}

.camera-container:hover .stop-video-btn {
 opacity: 0;
 visibility: hidden;

 transition: all .5s ease-in-out;
}

.camera-actions img {
 width: 92px;
 height: 92px;
}


.camera__top {
 display: flex;
 justify-content: space-between;

 position: absolute;
 top: 0;
 left: 0;

 width: 100%;
}

.camera__top__card, .camera__top__battery, .camera__top__mode {
 padding: 10px;
 color: #fff;
 display: flex;
 align-items: center;
 font-weight: bold;
 font-size: 12px;
}

.camera__top__mode img{
 cursor: pointer;
 margin-right: 10px;
}

.camera__top__card img {
 transform: rotate(-90deg);
}

.camera__top__battery img {
 margin-left: 4px;
 width: 32px;
 transform: rotate(90deg);
}

.camera-bottom {
 position: absolute;
 bottom: 0;
 left: 0;

 display: flex;
 justify-content: space-between;
 width: 100%;
}

/* Lens */
.camera__bottom__lens {
 position: relative;
 padding: 30px;
 display: flex;
 align-items: center;
 cursor: pointer;
}

.camera__bottom__lens span {
 z-index: 2;
 font-weight: bold;
 font-size: 12px;
 color: #000;
}

.camera__bottom__lens::before {
 content: '';
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 1;
 display: block;
 width: 24px;
 height: 24px;
 border-radius: 50%;
 background-color: #fff;
 margin-right: 10px;
}

.camera__bottom__lens::after {
 content: '';
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 0;
 display: block;
 width: 48px;
 height: 48px;
 border-radius: 50%;
 background-color: rgba(0, 0, 0, 0.5);
 margin-right: 10px;
}

/* Inverted */
.camera__bottom__lens--invert {
 position: relative;
 padding: 20px;
 display: flex;
 align-items: center;
 cursor: pointer;
}

.camera__bottom__lens--invert span {
 z-index: 2;
 font-weight: bold;
 font-size: 12px;
 color: #fff;
}

.camera__bottom--bigText span {
 font-size: 18px;
}

.camera__bottom__lens--invert::before {
 content: '';
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 1;
 display: block;
 width: 32px;
 height: 32px;
 border-radius: 50%;
 background-color: rgba(0, 0, 0, 0.8);
 margin-right: 10px;
 color: #fff;
}

.camera__bottom__lens--invert::after {
 content: '';
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 0;
 display: block;
 width: 64px;
 height: 64px;
 border-radius: 50%;
 background-color: #fff;
 margin-right: 10px;
}


/* Quality */
.camera__bottom__quality {
 position: relative;
 margin: 30px;
 padding: 12px 16px;
 display: flex;
 align-items: center;
 cursor: pointer;

 background-color: rgba(0, 0, 0, 0.5);
 border-radius: 24px;
}

.camera__bottom__quality span {
 z-index: 2;
 font-weight: bold;
 font-size: 14px;
 color: #fff;
}

/* Speed */
.camera__bottom__framing {
 position: relative;
 padding: 30px;
 display: flex;
 align-items: center;
 cursor: pointer;
}

/* Frame */
.camera__bottom__frame span {
 position: relative;
 z-index: 2;
 font-weight: bold;
 font-size: 18px;
 color: #000;
}

.camera__bottom__frame::before {
 content: '';
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 1;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 24px;
 height: 18px;
 background-color: #fff;
 color: #000;
 margin-right: 10px;
 font-size: 16px;

}

.camera__bottom__frame::after {
 content: '';
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 0;
 display: block;
 width: 48px;
 height: 48px;
 border-radius: 50%;
 background-color: rgba(0, 0, 0, 0.5);
 margin-right: 10px;
}

/* Lens Screen */
.camera-lens-screen {
 position: absolute;
 top: -8px;
 left: 0;
 z-index: 5;

 width: 630px;
 height: 435px;

 color: #fff;
 font-weight: bold;

 background-color: rgba(0, 0, 0, 0.8);

 display: flex;
 justify-content: space-between;
}

.camera-lens-screen--hidden {
 display: none;
}

.camera-lens-screen .content {
 width: 70%;
 text-align: left;

 padding: 20px;;
}

.camera-lens-screen .content .header {
 margin: 0;
 padding: 0 0 4px 0;

 font-size: 40px;
 font-weight: bold;

 text-transform: uppercase;
}

.camera-lens-screen .content .title {
 margin-bottom: 30px;

 font-size: 36px;
 font-weight: bold;

 color: rgb(0, 163, 244);
}

.camera-lens-screen .content .description {
 font-size: 24px;
 font-weight: normal;
 line-height: 1.5;
}


.camera-lens-screen .lens {
 margin: 20px 30px;
 width: 64px;
 border-radius: 80px;
 height: 380px;
 background-color: #555555;


 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: space-between;
}

.camera-lens-screen .lens img {
 width: 20%;
 cursor: pointer;
 margin: 30px 0;
}
